.footer-nav {
	// position: absolute;
	// z-index: 100000;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	height: 50px;
	box-shadow: 0 -1px 0 0 rgba($black, 0.1);
	background-color: $white;
	@media (prefers-color-scheme: dark) {
		box-shadow: 0 -1px 0 0 rgba($white, 0.1);
		background-color: $black;
	}
	.link {
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transition: all 0.2s ease-in-out;
		border: 0 solid;
		border-color: $primary;
		@media (prefers-color-scheme: dark) {
			border-color: $secondary;
		}
		&.active {
			color: $primary;
			@media (prefers-color-scheme: dark) {
				color: $secondary;
			}
			.avatarIcon {
				border-width: 3px;
			}
		}
		.avatarIcon {
			border-width: 0;
			transition: border-width 0.05s ease-in-out;
		}
	}
}
