.artist-profile {
	.hero {
		position: relative;
		height: 200px;
		background-color: #ccc;
		background: #ccc center/cover no-repeat;
		&:before {
			position: absolute;
			bottom: 0;
			left: 0;
			height: 80px;
			width: 300px;
			content: "";
			display: block;
			background: url("../assets/hero-bottom.svg") bottom left/contain no-repeat;
		}
	}

	.info {
		padding: 50px 0;
		h1 {
			font-weight: normal;
			color: $primary;
			text-transform: uppercase;
		}
		.bio {
			max-width: 500px;
		}
	}

	.events {
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;
		margin-bottom: 50px;
		.event {
			padding: 20px 0;
			& + .event {
				border-top: 1px solid #eee;
			}
			.day {
				font-size: 0.8em;
			}
			.name {
				color: $primary;
				text-transform: uppercase;
				font-weight: bold;
			}
			.times {
				font-size: 0.8em;
			}
		}
	}
}
