$height: 80px;
$width: 60px * 4.5;

$color1: #dbcdb6;
$color2: #89afbd;
$color3: #4b94b0;
$color4: #36697d;
$color5: #213b45;

.days-nav {
	background-color: var(--black);
	color: white;
	margin-left: -15px;
	margin-right: -15px;
	padding: 0 15px;

	ul {
		position: relative;
		padding: 0;
		margin: 0 -15px;
		list-style: none;
		display: grid;
		grid-auto-flow: column;
		text-align: center;
		grid-auto-columns: 1fr;
		.line {
			height: 2px;
			width: 33.33%;
			position: absolute;
			left: 0;
			bottom: 0;
			background-color: #bc1e2f;
			transition: left 0.3s cubic-bezier(0.19, 1, 0.22, 1);
		}
		li {
			height: 40px;
			border-bottom: 2px solid transparent;
			cursor: pointer;
			position: relative;
			a {
				cursor: pointer;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				border-bottom: 2px solid transparent;
				&.active {
					background-color: #000000;
					pointer-events: none;
				}
			}
		}
	}
}

.timetable {
	margin: 0 -15px 2rem;
	position: relative;
	border: 1px solid rgba(#000000, 0.1);

	.stages {
		width: 90px;
		padding-top: 30px;
		padding-bottom: 30px;
		box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1);

		.stage-name {
			background-image: linear-gradient(
				30deg,
				rgba(255, 255, 255, 0.05),
				rgba(0, 0, 0, 0.05)
			);

			height: $height;
			padding: 0 10px;
			display: flex;
			align-items: center;
			&:nth-child(even) {
				background-color: rgba($color: #000000, $alpha: 0.05);
			}
			h3 {
				font-size: 0.85em;
				font-weight: normal;
				margin: 0;
			}
		}
	}
	.calendar {
		position: absolute;
		top: 0;
		left: 90px;
		bottom: 0;
		right: 0;
		overflow-x: scroll;
		padding-top: 30px;
		padding-bottom: 30px;
		.calendar-row {
			height: $height;
			width: $width * 15;
			border-bottom: 1px solid rgba(#000000, 0.1);
			position: relative;
			--hue: 197;
			--saturation: 50%;
			--lightness: 50%;
			--alpha: 0.1;
			background-color: hsla(
				var(--hue),
				var(--saturation),
				var(--lightness),
				var(--alpha)
			);
			// &:nth-child(8n + 1) {
			// 	--lightness: 30%;
			// }
			// &:nth-child(8n + 2) {
			// 	--lightness: 40%;
			// }
			// &:nth-child(8n + 3) {
			// 	--lightness: 50%;
			// }
			// &:nth-child(8n + 4) {
			// 	--lightness: 60%;
			// }
			// &:nth-child(8n + 5) {
			// 	--lightness: 70%;
			// }
			// &:nth-child(8n + 6) {
			// 	--lightness: 80%;
			// }
			// &:nth-child(8n + 7) {
			// 	--lightness: 90%;
			// }
			// &:nth-child(8n + 8) {
			// 	--lightness: 100%;
			// }

			&:last-child,
			&:first-child {
				.hours {
					.hour:after {
						position: absolute;
						display: block;
						content: attr(data-hour) ":00";
						left: 5px;
						color: #999;
						font-size: 0.8em;
					}
				}
			}
			&:last-child {
				.hours {
					.hour:after {
						top: 100%;
					}
				}
			}
			&:first-child {
				.hours {
					.hour:after {
						bottom: 100%;
					}
				}
			}
			.hours {
				display: flex;
				.hour {
					position: relative;
					height: $height;
					border-left: 1px solid rgba(#000000, 0.1);

					width: $width;
					background-image: linear-gradient(
						45deg,
						rgba(255, 255, 255, 0.05),
						rgba(0, 0, 0, 0.05)
					);
					&:first-child {
						border-left: none;
					}
					&:nth-child(even) {
						background-color: rgba($color: #fff, $alpha: 0.25);
					}
				}
			}
			.calendar-card {
				min-width: 120px;
				border: 2px solid;
				position: absolute;
				top: 0;
				height: $height - 10;
				margin: 5px 0;
				background-color: #222;
				background-image: linear-gradient(
					45deg,
					rgba(255, 255, 255, 0.2),
					rgba(0, 0, 0, 0.2)
				);
				color: white;
				padding: 0 15px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 10px;
				box-shadow: 0 0 2px 1px rgba($color: #000000, $alpha: 0.05);
				// transform: scale(1.1);
				& > div {
					position: relative;
					max-width: 70%;
					h4 {
						font-size: 0.9em;
						margin-bottom: 0.25em;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						display: block;
					}
					.times {
						white-space: nowrap;
						font-size: 0.6em;
					}
				}
				.favourite {
					cursor: pointer;
				}
			}
		}
	}
}

.legend {
	.legend-item {
		display: flex;
		gap: 10px;
		justify-content: left;
		align-items: center;
		.color {
			border-radius: 50px;
			height: 20px;
			width: 20px;
			background-image: linear-gradient(
				45deg,
				rgba(255, 255, 255, 0.2),
				rgba(0, 0, 0, 0.2)
			);
		}
	}
}
